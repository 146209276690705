<template>
  <div class="fileImport">
    <div class="title">
      {{ title }}
    </div>
    <div class="fileImport-table">
      <a-spin :spinning="loading">
        <a-table :dataSource="dataSource" :columns="columns" />
      </a-spin>
    </div>

  </div>
</template>

<script>
export default {
  name: 'FileImport',
  data() {
    return {
      title: '来自导入文件',
      loading:false,
      dataSource: [
        {
          key: '1',
          name: '胡彦斌',
          age: 32,
          address: '西湖区湖底公园1号',
        },
        {
          key: '2',
          name: '胡彦祖',
          age: 42,
          address: '西湖区湖底公园1号',
        },
      ],
      columns: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '年龄',
          dataIndex: 'age',
          key: 'age',
        },
        {
          title: '住址',
          dataIndex: 'address',
          key: 'address',
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.fileImport {
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  text-align: left;
  .title {
   font-size: 18px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.85);
    font-family: PingFangSC;
  }
   .fileImport-table{
    padding: 20px;
    padding-top: 0;
  }
}
</style>